var __jsx = React.createElement;
import { Box, Button } from '@playful/design_system';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
import { CTAButton } from '../components/CallToAction/CTAButton';
import { useProjectSettingsContext } from './ProjectSettingsContext';
export function PublishButton(_ref) {
  var isLoading = _ref.isLoading;
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isPublished = _useProjectSettingsCo.isPublished,
    onPublished = _useProjectSettingsCo.onPublished,
    canPublish = _useProjectSettingsCo.canPublish,
    projectInfo = _useProjectSettingsCo.projectInfo;
  var handleClick = function handleClick() {
    onPublished();
  };
  return __jsx(Box, {
    mt: 4
  }, !isPublished && subscriptionPaywalls.publishProject(projectInfo) && __jsx(CTAButton, {
    width: '100%',
    onClick: handleClick,
    isDisabled: !canPublish,
    id: 'publish-button',
    isLoading: isLoading
  }, "publish it"), isPublished && __jsx(Button, {
    id: 'unpublish-button',
    width: '100%',
    onClick: handleClick,
    size: 'md',
    height: '40px',
    fontWeight: 'normal',
    isLoading: isLoading,
    backgroundColor: 'red.500',
    color: 'white'
  }, "unpublish"));
}