var __jsx = React.createElement;
import { VStack } from '@chakra-ui/react';
import { Button, CheckmarkIcon, FacebookIcon, HStack, IconButton, LinkIcon, MoreVerticalIcon, TwitterIcon } from '@playful/design_system';
import React from 'react';
import { useProjectSettingsContext } from './ProjectSettingsContext';
export function ProjectSharingOptions() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isLoading = _useProjectSettingsCo.isLoading,
    onLinkCopy = _useProjectSettingsCo.onLinkCopy,
    isLinkCopied = _useProjectSettingsCo.isLinkCopied,
    onCopyEmbed = _useProjectSettingsCo.onCopyEmbed,
    isEmbedCopied = _useProjectSettingsCo.isEmbedCopied,
    isLoadingEmbed = _useProjectSettingsCo.isLoadingEmbed,
    onSocialShare = _useProjectSettingsCo.onSocialShare,
    isNativeShare = _useProjectSettingsCo.isNativeShare,
    onNativeShare = _useProjectSettingsCo.onNativeShare;
  return __jsx(VStack, {
    w: "100%",
    spacing: 3,
    pb: 6,
    alignItems: "flex-start"
  }, __jsx(HStack, {
    spacing: 3,
    w: "100%"
  }, __jsx(Button, {
    flexGrow: 1,
    variant: "secondary",
    onClick: onCopyEmbed,
    isLoading: isLoadingEmbed,
    isDisabled: isLoadingEmbed
  }, !isEmbedCopied && 'copy embed', isEmbedCopied && 'embed copied!'), __jsx(Button, {
    isLoading: isLoading,
    isDisabled: isLoading,
    onClick: onLinkCopy,
    flexGrow: 1,
    leftIcon: isLinkCopied ? __jsx(CheckmarkIcon, null) : __jsx(LinkIcon, null),
    colorScheme: "yellow"
  }, !isLinkCopied && 'copy link', isLinkCopied && 'link copied!')), __jsx(HStack, {
    spacing: 1
  }, __jsx(IconButton, {
    variant: 'ghost',
    colorScheme: "light",
    size: "sm",
    onClick: function onClick() {
      return onSocialShare('tw');
    },
    icon: __jsx(TwitterIcon, null),
    "aria-label": "share on twitter"
  }), __jsx(IconButton, {
    variant: 'ghost',
    colorScheme: "light",
    size: "sm",
    icon: __jsx(FacebookIcon, null),
    onClick: function onClick() {
      return onSocialShare('fb');
    },
    "aria-label": "share on facebook"
  }), isNativeShare && __jsx(IconButton, {
    variant: 'ghost',
    colorScheme: "light",
    size: "sm",
    icon: __jsx(MoreVerticalIcon, null),
    onClick: onNativeShare,
    "aria-label": "more share options"
  })));
}