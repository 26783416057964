var __jsx = React.createElement;
import { Box, HStack, ProBadgeIcon, Switch, Tooltip } from '@playful/design_system';
import { subscriptionPaywalls } from '../workbench/SubscriptionPaywalls';
import React from 'react';
import { useProjectPermissions } from '../hooks/useProject';
import { PasswordProtectSetting } from './PasswordProtectSetting';
import { useProjectSettingsContext } from './ProjectSettingsContext';
import { SwitchField } from './SwitchField';
import { SwitchLabel } from './SwitchLabel';
export function ProjectPermissions() {
  var _useProjectSettingsCo = useProjectSettingsContext(),
    isLoading = _useProjectSettingsCo.isLoading,
    onError = _useProjectSettingsCo.onError,
    projectInfo = _useProjectSettingsCo.projectInfo;
  var _useProjectPermission = useProjectPermissions({
      id: projectInfo.id,
      onSetShared: function onSetShared(err, isShared) {
        return err && (onError === null || onError === void 0 ? void 0 : onError("your project couldn't be ".concat(isShared ? 'shared' : 'unshared')));
      },
      onSetRemixable: function onSetRemixable(err, isRemixable) {
        return err && (onError === null || onError === void 0 ? void 0 : onError("your project couldn't be set to ".concat(isRemixable ? 'remixable' : 'unremixable')));
      },
      onSetBranding: function onSetBranding(err) {
        return err && (onError === null || onError === void 0 ? void 0 : onError("your project's branding couldn't be set."));
      }
    }),
    setShared = _useProjectPermission.setShared,
    disableShare = _useProjectPermission.disableShare,
    setRemixable = _useProjectPermission.setRemixable,
    setBranding = _useProjectPermission.setBranding,
    hasBranding = _useProjectPermission.hasBranding,
    isShared = _useProjectPermission.isShared,
    isRemixable = _useProjectPermission.isRemixable;
  function handleHatchLogoChange() {
    if (subscriptionPaywalls.removeHatchLogo()) {
      setBranding === null || setBranding === void 0 || setBranding(!hasBranding);
    } else {
      subscriptionPaywalls.openModal({
        reason: 'removewatermark',
        returnTo: ['project-settings-popover']
      });
    }
  }
  return __jsx(React.Fragment, null, __jsx(SwitchField, {
    isDisabled: disableShare
  }, __jsx(Box, {
    pr: 4
  }, __jsx(SwitchLabel, {
    htmlFor: "share-on-hatch",
    tooltipLabel: 'Visible on the public Hatch gallery and your profile page'
  }, "share with the Hatch community")), __jsx(Switch, {
    id: "share-on-hatch",
    size: "md",
    isDisabled: isLoading || disableShare,
    onChange: function onChange() {
      return setShared === null || setShared === void 0 ? void 0 : setShared(!isShared);
    },
    isChecked: isShared
  })), __jsx(SwitchField, null, __jsx(Box, {
    pr: 4
  }, __jsx(SwitchLabel, {
    htmlFor: "allow-remixing",
    tooltipLabel: 'Allows anyone to create a copy of your project and remix it as their own'
  }, "allow remixing")), __jsx(Switch, {
    id: "allow-remixing",
    size: "md",
    isDisabled: isLoading,
    onChange: function onChange() {
      return setRemixable === null || setRemixable === void 0 ? void 0 : setRemixable(!isRemixable);
    },
    isChecked: isRemixable
  })), __jsx(SwitchField, null, __jsx(Box, {
    pr: 4
  }, __jsx(SwitchLabel, {
    htmlFor: "made-with-play",
    tooltipLabel: 'Spread awareness of the Hatch community by including a link back to Hatch on your published site'
  }, "remove hatch watermark")), __jsx(HStack, {
    spacing: 2
  }, !subscriptionPaywalls.removeHatchLogo() && __jsx(Tooltip, {
    hasArrow: true,
    offset: [0, 12],
    placement: "top",
    label: "Upgrade to Pro to remove Hatch watermark."
  }, __jsx(Box, {
    as: "span",
    cursor: "help"
  }, __jsx(ProBadgeIcon, {
    h: 5,
    w: 5,
    "data-testid": 'watermark-pro-badge'
  }))), __jsx(Switch, {
    "aria-label": "made with play link",
    id: "made-with-play",
    size: "md",
    isDisabled: isLoading,
    onChange: handleHatchLogoChange,
    isChecked: !hasBranding
  }))), __jsx(PasswordProtectSetting, null));
}